<template>
  <div class="city-picker" v-if="!hidden">
    <div class="card-header">
      <span
        class="address-item"
        v-if="province.name"
        @click="province = city = district = street = {}"
        >{{ province.name }}</span
      >
      <span
        class="address-item"
        v-if="city.name"
        @click=" city =district = street = {}"
        >{{ city.name }}</span
      >
      <span
        class="address-item"
        v-if="district.name"
        @click="district = street = {}"
        >{{ district.name }}</span
      >
      <span class="address-item" v-if="street.name">{{ street.name }}</span>
      <span class="title" v-if="!loading">{{ pickerTitle }}</span>
      <i class="el-icon-circle-close" @click="$emit('update:hidden', true)"></i>
    </div>
    <div class="card-body" v-loading="loading">
      <div
        class="item"
        v-for="(item, index) in cities"
        :key="index"
        @click="change(item.code, item.name)"
        :title="item.name"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import cities from "@/common/config/user/cities.config.js";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    hidden: {
      type: Boolean,
      default: true,
    },
    deep: {
      type: Number,
      default: 4,
      validator: (value) => {
        return value >= 1 && value <= 4;
      },
    },
    address: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      province: {
        name: "",
        code: -1,
      },
      city: {
        name: "",
        code: -1,
      },
      district: {
        name: "",
        code: -1,
      },
      street: {
        name: "",
        code: -1,
      },
      loading: false,
      addressDeep: 0, //选择栏展示地址级数
    };
  },
  watch: {
    hidden(e) {
      if (e) {
        const name = "",
          code = -1;
        const address = { name, code };
        this.province = address;
        this.city = address;
        this.district = address;
        this.street = address;
        this.addressDeep = 0;
      } else {
        this.getDeep();
        const {
          Province,
          ProvinceCode,
          City,
          CityCode,
          District,
          DistrictCode,
          Town,
          TownCode,
        } = this.address;

        this.province = { name: Province, code: ProvinceCode };

        this.city = { name: City, code: CityCode };

        this.district = { name: District, code: DistrictCode };

        this.street = { name: Town, code: TownCode };
      }
    },
    district(e){
      if(!e.name){
        this.addressDeep = this.deep
      }
    }
  },
  computed: {
    pickerTitle() {
      let title = "";
      if (!this.province.name) title = "选择省份/直辖市/自治区";
      else if (!this.city.name) title = "选择城市/地区";
      else if (!this.district.name) title = "选择区/县/镇/乡/街道";
      else if (!this.street.name && this.addressDeep==4) title = "选择街道/镇/乡";
      return title;
    },
    cities() {
      let cityArray = [];
      if (!this.province.name) {
        // this.loading = true;
        let provinceList = this.getProvince();
        if (provinceList.length == 0) {
          this.getProvinceList();
        }
        cityArray = provinceList;
        // this.loading = false;
      } else if (!this.city.name) {
        //   if(this.cityList.length==0){

        //   }
        const code = this.province.code;
        let city = this.$store.getters.getCity(code);
        if (!city) {
          this.getCityList(code);
        }
        // this.getCity(code);
        if (city) {
          cityArray = city.data;
        }
      } else if (!this.district.name || this.addressDeep == 3) {
        const code = this.city.code;
        let area = this.$store.getters.getDistrict(code);
        if (!area) {
          this.getDistrictList(code);
        }
        if (area) {
          cityArray = area.data;
        }
      } else if (!this.street.name || this.addressDeep == 4) {
        const code = this.district.code;
       
        let street = this.$store.getters.getStreet(code);
        if (!street) {
          this.getStreetList(code);
        }
        if (street) {
          cityArray = street.data;
          if (cityArray.length == 0) {
            this.update();
          }
        }
      }
      return cityArray;
    },
  },
  methods: {
    ...mapGetters(["getProvince"]),
    ...mapActions(["setProvince", "setCity", "setDistrict", "setStreet"]),
    getDeep() {
      if (!this.address.Province) {
        this.addressDeep = this.deep;
        return;
      }

      if (this.address.Town) {
        this.addressDeep = 4;
      } else {
        this.addressDeep = 3;
      }
    },
    getProvinceList() {
      this.loading = true;
      this.$axios.get("/api/UserAddress/GetProvince", "").then((res) => {
        if (res.data.length > 0) {
          //   const data = JSON.parse(res.data);
          //   let list = [];
          //   for (let key in data) {
          //     list.push({ name: key, code: data[key] });
          //   }
          //   list.sort((a, b) => {
          //     return a.code - b.code;
          //   });
          this.setProvince(res.data);
          // this.provinceList.sort((a,b)=>{
          // 	return a.value-b.value
          // })
          this.loading = false;
        } else {
          this.notifyError("未获取到省份信息");
          this.loading = false;
        }
      });
    },
    getCityList(code) {
      this.loading = true;
      this.$axios
        .get("/api/UserAddress/GetCity", {
          params: { code: code },
        })
        .then((res) => {
          if (res.data) {
            this.setCity({ code: code, data: res.data });
            this.loading = false;
          }
        });
    },
    getDistrictList(code) {
      this.loading = true;
      this.$axios
        .get("/api/UserAddress/getCounty", {
          params: { code: code },
        })
        .then((res) => {
          if (res.data) {
            this.setDistrict({ code: code, data: res.data });
            this.loading = false;
          }
        });
    },
    getStreetList(code) {
      this.loading = true;
      this.$axios
        .get("/api/UserAddress/getTown", {
          params: { code: code },
        })
        .then((res) => {
          if (res.data) {
            this.setStreet({ code: code, data: res.data });
            this.loading = false;
          }
        });
    },
    change(code, name) {
      // debugger
      if(this.addressDeep==0){
        this.addressDeep=this.deep
      }
      // 赋值
      const address = {
        name,
        code,
      };
      let returnAddress = false;
      if (!this.province.name ) {
        if (this.addressDeep == 1) returnAddress = true;
        this.province = address;
      } else if (!this.city.name && this.addressDeep >= 2) {
        if (this.addressDeep == 2) returnAddress = true;
        this.city = address;
      } else if (!this.district.name && this.addressDeep >= 3 ||this.addressDeep ==3) {
        if (this.addressDeep == 3) returnAddress = true;
        this.district = address;
      } else if (!this.street.name && this.addressDeep == 4||this.addressDeep ==4) {
        returnAddress = true;
        this.street = address;
      }
      // 返回值
      if (returnAddress) {
        this.update();
      }
    },
    update() {
      let { province, city, district, street } = this;
      this.$emit("change", {
        Province: province.name,
        City: city.name,
        District: district.name,
        Town: street.name,
        ProvinceCode: province.code,
        CityCode: city.code,
        DistrictCode: district.code,
        TownCode: street.code,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.city-picker {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $bg-color;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .card-header {
    position: relative;
    padding: 6px 0;
    line-height: 30px;
    border-bottom: 1px solid #e0e0e0;

    .address-item {
      color: $color-primary;
      margin-right: 5px;
      cursor: pointer;
    }

    .title {
      color: #757575;
    }

    i {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 10px;
      font-weight: bold;
      color: #b0b0b0;
    }
  }

  .card-body {
    max-height: 180px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    background-color: $bg-color;
    min-height: 100px;
    margin-left: 10px;
    .item {
      //   margin-right: 14px;
      line-height: 30px;
      margin-bottom: 6px;
      color: $text-color;
      cursor: pointer;
      width: 98px;
      text-align: center;
      white-space: nowrap;
      padding-right: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        color: $color-primary;
      }
    }
  }
}
</style>
